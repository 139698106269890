import React from 'react';
import {render} from 'react-dom';

import {Sprite} from '@nur-portal/ui-kit';

const spriteElement = document.querySelector('#js-svg-sprite');

if (spriteElement) {
  render(<Sprite />, spriteElement);
}
